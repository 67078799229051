import { Component, Vue } from "vue-property-decorator";

import { SideNav } from "@/layout";
import { QuizModel } from "@/models";
import { Common } from "../Common";

export class QuizForm {
  name = '';
  beginTime = '';
  endTime = '';
  city = '';
  school = '';
  class = '';
  teacher = '';
  grade = '';
  unit = '';
  level = '';
  num = 0;
  qid = '';
}

type response = { [key: string]: unknown };

@Component<AdminQuizPaperInfoController>({
  components: {
    SideNav
  }
})
export default class AdminQuizPaperInfoController extends Vue {
  /*定義data 變數 */
  private doubleClick = false;
  private quizForm = new QuizForm();
  private questionList: object = {};
  private token = window.localStorage.getItem('adminToken') as string;

  public async created() {
    const item: { [key: string]: string } = {
      qid: this.$route.query.qid as string,
      token: this.token,
    }
    const editData = await QuizModel.getPaperInfo(item) as response;
    this.assignDataToForm(editData);
  }

  private assignDataToForm(data: response) {
    Common.checkToken(data.ERR_CODE as number);

    const item = data.info as {
      qid: string;
      name: string;
      beginTime: string;
      endTime: string;
      city: string;
      school: string;
      class: string;
      teacher: string;
      grade: string;
      unit: string;
      level: string;
      num: number;
    };
    this.quizForm.qid = item.qid;
    this.quizForm.name = item.name;
    this.quizForm.beginTime = item.beginTime;
    this.quizForm.endTime = item.endTime;
    this.quizForm.city = item.city;
    this.quizForm.school = item.school;
    this.quizForm.class = item.class;
    this.quizForm.teacher = item.teacher;
    this.quizForm.grade = item.grade;
    this.quizForm.unit = item.unit;
    this.quizForm.level = item.level;
    this.quizForm.num = item.num;

    this.questionList = data.list as object;
  }
}
